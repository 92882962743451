import React, { useRef, useState, useEffect } from "react";
import SchoolsCard from "./_components/SchoolsCard";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { IoIosSearch } from "react-icons/io";
import { authAxios } from "../../config/axios-config";
import { Loader } from "semantic-ui-react";

export default function BreakingLimits() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEn, setIsLoadingEn] = useState(false);
  const [isLoadingAr, setIsLoadingAr] = useState(false);
  const [materialEn, setMaterialEn] = useState("");
  const [materialAr, setMaterialAr] = useState("");
  const [schoolsInterested, setSchoolsInterested] = useState([]);
  const [searchName, setSearchName] = useState("");

  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];
  const schoolId = localStorage.getItem("id");
  const getMaterial = async (language) => {
    try {
      const response = await authAxios.get(`/schools/${schoolId}/${language}`);
      const downloadLink = response?.data?.data?.downloadLink;
      console.log(downloadLink);

      if (language === "en") {
        setMaterialEn(downloadLink);
      }
      if (language === "ar") {
        setMaterialAr(downloadLink);
      }
    } catch (error) {
      console.error("Error fetching material:", error);
    }
  };

  const viewInterestedSchools = async () => {
    try {
      setIsLoading(true);
      const response = searchName
        ? await authAxios.get(`/schools/downloads?search=${searchName}`)
        : await authAxios.get(`/schools/downloads`);
      setSchoolsInterested(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching schools:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMaterial("en");
    getMaterial("ar");
  }, []);

  useEffect(() => {
    viewInterestedSchools();
  }, [searchName]);

  return (
    <section className="mx-5">
      <div className="flex flex-wrap items-center justify-between rounded-lg px-10 py-3 font-medium">
        <div className="md: relative w-[20rem] rounded-md">
          <IoIosSearch className="absolute top-1/2 left-3 -translate-y-1/2 font-bold text-black" />
          <input
            onChange={(e) => setSearchName(e.target.value)}
            className="w-full rounded-lg border-2 border-gray-300 py-2 px-4 pl-10 caret-primary-900 focus:outline-none"
            type="search"
          />
        </div>
        <div className="flex items-center gap-4">
          <div
            onClick={() => setIsLoadingEn(true)}
            className="relative rounded-lg bg-gray-200 px-8 py-2"
            disabled={isLoadingEn}
          >
            <a href={materialEn} download={materialEn}>
              {selectedContent[localizationKeys.DownloadMaterialByEnglish]}{" "}
              <span className="text-primary-900">(EN)</span>
              <Loader
                size="tiny"
                className="absolute left-2 top-1/2 -translate-y-1/2"
              />
            </a>
          </div>
          <div
            onClick={() => setIsLoadingAr(true)}
            className="relative rounded-lg bg-gray-200 px-8 py-2"
            disabled={isLoadingAr}
          >
            <a href={materialAr} download={materialAr}>
              {selectedContent[localizationKeys.DownloadMaterialByArabic]}{" "}
              <span className="text-primary-900">(Ar)</span>
              <Loader
                size="tiny"
                className="absolute left-2 top-1/2 -translate-y-1/2"
              />
            </a>
          </div>
        </div>
      </div>
      <h2 className="my-8 px-4 text-2xl font-bold text-primary-900">
        {selectedContent[localizationKeys.allSchoolsProfiles]} :
      </h2>
      <div className="flex flex-wrap">
        {isLoading ? (
          <Loader size="medium" active={true} />
        ) : (
          <SchoolsCard schoolsInterested={schoolsInterested} />
        )}
      </div>
    </section>
  );
}
