import React from "react";
import Img from "../../../assets/pictures/landingpage-picture.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import routes from "../../../routes";
import { authAxios } from "../../../config/axios-config";
import { useLanguage } from "../../../lib/language-context";
import content from "../../../localization/content";
import localizationKeys from "../../../localization/localization-keys";
export default function SchoolsCard({ schoolsInterested }) {
  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];
  const history = useHistory();
  return schoolsInterested?.map((school) => (
    <div
      onClick={() =>
        history.push(`${routes.BreakingLimits.school(school._id)}`)
      }
      key={school?._id}
      className="w-full p-2 sm:w-1/3 lg:w-1/4 cursor-pointer group"
    >
      <div className="flex h-full flex-col rounded-lg border border-gray-200 p-6">
        <div className="mb-4 h-[200px] overflow-hidden">
          <img
            src={school?.image_link}
            className="w-full rounded-lg object-cover group-hover:scale-105 transition-all duration-500"
            alt="School"
          />
        </div>
        <div className="flex-grow border-t border-gray-200 px-4 pt-4">
          <div>
            <h3>
              <span className="text-primary-900">
                {selectedContent[localizationKeys.schoolName]}
              </span>{" "}
              :{" "}
              {document.documentElement.getAttribute("dir") === "ltr"
                ? school?.name?.en?.split(" ").length > 3
                  ? school?.name?.en?.split(" ").slice(0, 3).join(" ") + "..."
                  : school?.name?.en
                : school?.name?.ar?.split(" ").length > 3
                ? school?.name?.ar?.split(" ").slice(0, 3).join(" ") + "..."
                : school?.name?.ar}
            </h3>
            <p className="text-gray-900">
              <span className="text-gray-600">
                {selectedContent[localizationKeys.studentGender]}
              </span>{" "}
              : {`(${school?.students_gender?.join(" , ")})`}
            </p>
            <p className="text-gray-900">
              <span className="text-gray-600">
                {selectedContent[localizationKeys.educationalStage]}{" "}
              </span>
              :{`(${school?.programInfo?.educationStage?.join(" , ")})`}
            </p>
          </div>
        </div>
      </div>
    </div>
  ));
}
