import React from "react";
import { Image, Progress, Message } from "semantic-ui-react";
import { AiOutlineMail } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import EducationalOfficeIcon from "../../../assets/icons/educational-office-icon";
import EducationalLevelIcon from "../../../assets/icons/educational-level-icon";
import GenderIcon from "../../../assets/icons/gender-icon";
import { BsGenderFemale, BsGenderMale, BsTag } from "react-icons/bs";
import { TbNotes } from "react-icons/tb";
import LocationIcon from "../../../assets/icons/location-icon";
import GlobeIcon from "../../../assets/icons/world-icon";
import content from "../../../localization/content";
import { useLanguage } from "../../../lib/language-context";
import localizationKeys from "../../../localization/localization-keys";
import useMediaQuery from "../../../components/hooks/use-media-query";
import { truncateString } from "../../../utils/string";
import ImageEmptyState from "../../../assets/icons/image-empty-state";

export default function SchoolProfile({ school }) {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const isSmall = useMediaQuery("(max-width: 776px)");
  return (
    <>
      {!window.navigator.onLine && (
        <Message
          error
          className="mb-8"
          content={selectedContent[localizationKeys.noInternet]}
        />
      )}
      <div
        className={`relative z-0 h-[14.188rem] flex-col items-end rounded-2xl bg-cover
${!school?.data?.image_link && `bg-[#D3D3D3]`}
`}
        style={{
          backgroundImage:
            school?.data?.image_link && `url(${school?.data?.image_link})`,
        }}
      >
        <div
          className={`flex h-32 items-center justify-end rounded-2xl  
${school?.data?.image_link && `bg-gradient-to-b from-gray-700 to-[#2C2C2C00]`}
`}
        ></div>
        {!school?.data?.image_link && (
          <ImageEmptyState className="mx-auto -mt-8 h-11 w-14 fill-white" />
        )}
        <div
          className={`absolute bottom-0 ${
            isSmall ? "" : "flex items-center justify-between"
          } z-10 w-full
  ${
    school?.data?.image_link
      ? `bg-gradient-to-t from-gray-700 to-[#2C2C2C00]`
      : `bg-gradient-to-t from-gray-500 to-[#2C2C2C00]`
  } rounded-xl p-4 `}
        >
          <div className="flex items-center ">
            {school?.data?.logo_link ? (
              <Image
                className={`${isSmall ? "h-16 w-16" : "h-20 w-20"}`}
                src={school?.data?.logo_link ? school?.data?.logo_link : ""}
                circular
                size="mini"
              />
            ) : (
              <div
                className={`${
                  isSmall ? "h-16 w-16" : "h-20 w-20"
                } flex items-center justify-center rounded-full bg-[#E9E9E9] `}
              >
                <ImageEmptyState className="h-8 w-8 fill-[#a7a7a7]" />
              </div>
            )}
            <div className="flex-col">
              <div
                className={`mx-2 mt-2 text-white ${
                  isSmall ? "text-base" : "text-2xl"
                }  font-normal`}
              >
                {lang === "ar"
                  ? truncateString(school?.data?.name?.ar, 30)
                  : truncateString(school?.data?.name?.en, 30)}
              </div>
              {isSmall ? (
                <div className="mx-2 -mt-1 flex items-center gap-2 text-white">
                  <span className="text-sm">
                    {selectedContent[localizationKeys.freeTrial]}
                  </span>
                  <span> {3 - school?.data?.vacancies_limit}/3</span>
                  <Progress
                    percent={((3 - school?.data?.vacancies_limit) / 3) * 100}
                    size="tiny"
                    className="z-10 mt-5 w-12 "
                  />
                </div>
              ) : null}
            </div>
          </div>
          {!isSmall ? (
            <div className="flex items-center gap-2 text-white ">
              <span> {selectedContent[localizationKeys.freeTrial]}</span>
              <span> {3 - school?.data?.vacancies_limit}/3</span>
              <Progress
                percent={((3 - school?.data?.vacancies_limit) / 3) * 100}
                size="tiny"
                className="z-10 mt-5 w-24"
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-4">{school?.data?.breif}</div>
      {isSmall ? (
        <>
          <div className="mt-6 h-full gap-2 rounded-lg bg-white p-4 shadow-[0px_0px_6px_rgba(0,0,0,0.09)]">
            <div className="flex items-center gap-2">
              <GlobeIcon />
              <div>{school?.data?.city?.name}</div>
            </div>
            <div className="mt-2 flex items-start gap-2">
              <LocationIcon />
              <div>{school?.data?.address}</div>
            </div>
            <div className="mt-2 flex items-center gap-2">
              <AiOutlineMail className="fill-primary-900" />
              <div>{school?.data?.email}</div>
            </div>
            <div className="mt-2 flex items-center gap-2">
              <BsTelephone className="fill-primary-900" />
              <div dir="ltr">
                {school?.data?.phone_country_code}
                {school?.data?.phone}
              </div>
            </div>
          </div>
          <div className="mt-2 h-full gap-2 rounded-lg bg-white p-4 shadow-[0px_0px_6px_rgba(0,0,0,0.09)] ">
            <div className="flex items-center gap-2">
              <EducationalOfficeIcon />
              <div>
                {school?.data?.education_centers?.name}-
                {school?.data?.education_centers?.gender}
              </div>
            </div>
            <div className="mt-2 flex items-center gap-2">
              <EducationalLevelIcon />
              <div className="w-max">
                {school?.data?.education_levels?.map((d, index) => (
                  <React.Fragment key={index}>
                    <span className="mx-0.5">{d?.name}</span>
                    {index !== school?.data?.education_levels?.length - 1 && (
                      <span>-</span>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="mt-2 flex items-center gap-2">
              {school?.data?.students_gender?.length === 1 &&
                school?.data?.students_gender[0] === "بنين" && (
                  <BsGenderMale className="fill-primary-900 text-primary-900" />
                )}
              {school?.data?.students_gender?.length === 1 &&
                school?.data?.students_gender[0] === "بنات" && (
                  <BsGenderFemale className="fill-primary-900 text-primary-900" />
                )}
              {school?.data?.students_gender?.length === 2 && (
                <GenderIcon className="fill-primary-900 text-primary-900" />
              )}
              <span>
                {school?.data?.students_gender[0]}
                {school?.data?.students_gender[1] && (
                  <span>- {school?.data?.students_gender[1]}</span>
                )}
              </span>
            </div>
            <div className="mt-2 flex items-center gap-2">
              <BsTag className="fill-primary-900 text-primary-900" />
              <div>{school?.data?.school_type}</div>
            </div>
            {school?.data?.commercial_registration_num && (
              <div className="mt-2 flex items-center gap-2">
                <TbNotes className="text-primary" />
                <div>{school?.data?.commercial_registration_num}</div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className="mx-36 mt-6 grid h-full grid-cols-2 gap-2 
rounded-lg bg-white p-4 shadow-[0px_0px_6px_rgba(0,0,0,0.09)]"
        >
          <div>
            <div className="flex items-center gap-2">
              <GlobeIcon />
              <div>{school?.data?.city?.name}</div>
            </div>
            <div className="mt-2 flex items-start gap-2">
              <LocationIcon />
              <div>{school?.data?.address}</div>
            </div>
            <div className="mt-2 flex items-center gap-2">
              <AiOutlineMail className="fill-primary-900" />
              <div>{school?.data?.email}</div>
            </div>
            <div className="mt-2 flex items-center gap-2">
              <BsTelephone className="fill-primary-900" />
              <div dir="ltr">
                {school?.data?.phone_country_code}
                {school?.data?.phone}
              </div>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-2">
              <EducationalOfficeIcon />
              <div>
                {school?.data?.education_centers?.name}-
                {school?.data?.education_centers?.gender}
              </div>
            </div>
            <div className="mt-2 flex items-center gap-2">
              <EducationalLevelIcon />
              <div className="w-max">
                {school?.data?.education_levels?.map((d, index) => (
                  <React.Fragment key={index}>
                    <span className="mx-0.5">{d?.name}</span>
                    {index !== school?.data?.education_levels?.length - 1 && (
                      <span>-</span>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="mt-2 flex items-center gap-2">
              {school?.data?.students_gender?.length === 1 &&
                school?.data?.students_gender[0] === "بنين" && (
                  <BsGenderMale className="fill-primary-900 text-primary-900" />
                )}
              {school?.data?.students_gender?.length === 1 &&
                school?.data?.students_gender[0] === "بنات" && (
                  <BsGenderFemale className="fill-primary-900 text-primary-900" />
                )}
              {school?.data?.students_gender?.length === 2 && (
                <GenderIcon className="fill-primary-900 text-primary-900" />
              )}
              <span>
                {school?.data?.students_gender[0]}
                {school?.data?.students_gender[1] && (
                  <span>- {school?.data?.students_gender[1]}</span>
                )}
              </span>
            </div>
            <div className="mt-2 flex items-center gap-2">
              <BsTag className="fill-primary-900 text-primary-900" />
              <div>{school?.data?.school_type}</div>
            </div>
            {school?.data?.commercial_registration_num && (
              <div className="mt-2 flex items-center gap-2">
                <TbNotes className="text-primary-900" />
                <div>{school?.data?.commercial_registration_num}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
