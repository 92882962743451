import { Formik } from "formik";
import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Form } from "semantic-ui-react";
import * as Yup from "yup";
import { axios } from "../../config/axios-config";
import routes from "../../routes";
import Auth from "../../utils/auth";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import toast from "react-hot-toast";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import useAxios from "../../lib/use-axios";
import FormikInput from "../../components/formik/formik-input";
import api from "../../api";
import loginImage from "../../assets/pictures/login.png";
import AuthNavbarComponent from "../../components/shared/auth-navbar-component";

const LoginPage = () => {
  const [lang] = useLanguage();
  const history = useHistory();
  const { run, isLoading } = useAxios();
  const selectedContent = content[lang];
  const [email, setEmail] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [emailNotVerified, setEmailNotVerified] = React.useState(false);
  const {
    run: sendEmailVerificationLink,
    isLoading: isSendingEmailVerificationLink,
  } = useAxios();

  const loginSchema = Yup.object({
    email: Yup.string()
      .required(selectedContent[localizationKeys.required])
      .email(selectedContent[localizationKeys.emailError]),
    password: Yup.string().required(selectedContent[localizationKeys.required]),
  });

  const onLogin = (values) => {
    run(axios.post(api.auth.login, values))
      .then(({ data }) => {
        
        if (data.verificationState) {
          Auth.setToken({
            newAccessToken: data?.data?.token,
            newRefreshToken: data?.data?.refreshToken,
          });
          localStorage.setItem("name_ar", data?.data?.name?.ar);
          localStorage.setItem("name_en", data?.data?.name?.en);
          localStorage.setItem("id", data?.data?._id);
          localStorage.setItem(
            "isCompleteProfile",
            data?.data?.isCompleteProfile
          );
          data?.data?.isCompleteProfile
            ? history.push(routes.home)
            : history.push(routes.completeProfile.home(data?.data?._id));
        } else {
          setEmailNotVerified(true);
        }
      })
      .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
  };

  const onSendingEmailVerificationLink = () => {
    sendEmailVerificationLink(
      axios.post(api.auth.resendEmailLink, { email: email })
    )
      .then(() => {
        toast.success(selectedContent[localizationKeys.linkIsSentSuccessfully]);
      })
      .catch((e) => e?.errors?.map((s) => toast.error(s.message)));
  };

  return (
    <div>
      <AuthNavbarComponent />
      <div
        className="flex h-screen w-screen items-center justify-center bg-slate-50"
        style={{
          backgroundImage: `url(${loginImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="max-w-sm flex-grow justify-center rounded-lg bg-white px-4 py-8 ">
          <h2 className="text-center text-xl text-gray-600">
            {selectedContent[localizationKeys.welcomeMessage]}
          </h2>
          {emailNotVerified && (
            <div className="mt-4 flex items-center text-sm text-gray-600">
              <div>{selectedContent[localizationKeys.emailIsNotVerified]}</div>
              <Button
                size="tiny"
                primary
                basic
                onClick={() => {
                  onSendingEmailVerificationLink();
                }}
                disabled={isSendingEmailVerificationLink}
              >
                {selectedContent[localizationKeys.verifyEmailThroughThisLink]}
              </Button>
            </div>
          )}
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={loginSchema}
            onSubmit={onLogin}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit} loading={isLoading}>
                <FormikInput
                  name="email"
                  placeholder={selectedContent[localizationKeys.email]}
                  dir="ltr"
                  onChangeCallback={(e) => {
                    setEmail(e);
                  }}
                />
                <FormikInput
                  name="password"
                  type={showPassword === true ? "text" : "password"}
                  placeholder={selectedContent[localizationKeys.password]}
                  icon={
                    showPassword === true ? (
                      <>
                        <FaEyeSlash
                          className="absolute mt-2 flex items-center justify-center text-gray-600 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                          size={20}
                          onClick={() => setShowPassword(false)}
                        />
                      </>
                    ) : (
                      <>
                        <FaEye
                          className="absolute mt-2 flex items-center justify-center text-gray-600 hover:cursor-pointer ltr:right-0 ltr:mr-2 rtl:left-0 rtl:ml-2"
                          size={20}
                          onClick={() => setShowPassword(true)}
                        />
                      </>
                    )
                  }
                />
                <a
                  href
                  onClick={() => history.push(routes.auth.forgotPassword)}
                  className="mx-2 my-4 flex justify-end text-sm font-bold text-gray-600 hover:cursor-pointer hover:underline"
                >
                  {selectedContent[localizationKeys.forgotPassword]}
                </a>
                <Button
                  primary
                  className="my-4 block h-12 w-full"
                  type="submit"
                >
                  {selectedContent[localizationKeys.loginButton]}
                </Button>
              </Form>
            )}
          </Formik>
          <div className="my-4 flex items-center justify-center ">
            <div className="font-bold text-gray-600">
              {selectedContent[localizationKeys.dontHaveAnAccont]}
            </div>
            <a
              href
              onClick={() => history.push(routes.auth.signup)}
              className="mx-2 font-bold text-primary-900 hover:cursor-pointer hover:underline"
            >
              {selectedContent[localizationKeys.registration]}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
