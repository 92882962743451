import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import { Confirm, Dropdown, Image, Sidebar } from "semantic-ui-react";

import { FaSlidersH } from "react-icons/fa";
import routes from "../../routes";
import localizationKeys from "../../localization/localization-keys";
import logo from "../../assets/pictures/logo-with-blue-background.svg";
import { MdOutlinePowerSettingsNew } from "react-icons/md";
import auth from "../../utils/auth";
import MainPageIcon from "../../assets/icons/main-icon";
import EmploymentApplicationsIcon from "../../assets/icons/employment-Applications-icon";
import JobsIcon from "../../assets/icons/jobs-icon";
import { MdKeyboardArrowDown } from "react-icons/md";
import ActiveJobsIcon from "../../assets/icons/active-jobs-icon";
import DraftedJobsIcon from "../../assets/icons/drafted-jobs-icon";
import ClosedJobIcon from "../../assets/icons/closed-jobs-icon";
import PaymentIcon from "../../assets/icons/payment-icon";
import SettingsIcon from "../../assets/icons/settings-icon";
import { BsGlobe } from "react-icons/bs";
import InstructorsIcon from "../../assets/icons/instructors-icon";
import BreakingLimitsIcon from "../../assets/icons/breaking-limits-Icon";

const SidebarComponent = ({ isVisible, isSmall, setIsVisible }) => {
  const [lang, setLang] = useLanguage();
  const location = useLocation();
  const selectedContent = content[lang];
  const [isConfirmOpen, setIsConfirmOpen] = React.useState(false);
  const [isViewingJobsDropdown, setIsViewingJobsDropdown] =
    React.useState(true);

  return (
    <>
      <Sidebar
        className="rounded-tl-3x w-64 overflow-hidden bg-primary-900 shadow-none ltr:pl-4 rtl:pr-4"
        visible={!isSmall || isVisible}
        animation="overlay"
      >
        <div
          className={`flex items-center ${
            isSmall ? `justify-between` : `justify-center`
          } mb-4 py-4`}
        >
          {isSmall && (
            <FaSlidersH
              size={30}
              className="mx-2 rounded-lg bg-[#FCFCFC] fill-primary-900 p-2 hover:cursor-pointer"
              onClick={() => setIsVisible(!isVisible)}
            />
          )}
          <Image src={logo} className="mx-auto mt-4 text-center" />
          <div></div>
        </div>
        <SidebarLink
          to={routes.home}
          text={selectedContent[localizationKeys.mainPage]}
          icon={MainPageIcon}
          isActive={location.pathname.startsWith(routes.home)}
        />
        <SidebarLink
          to={routes.BreakingLimits.all}
          text={selectedContent[localizationKeys.BreakingLimits]}
          icon={BreakingLimitsIcon}
          isActive={location.pathname.startsWith(routes.BreakingLimits.all)}
        />
        <SidebarLink
          to={routes.instructor.all}
          text={selectedContent[localizationKeys.instructors]}
          icon={InstructorsIcon}
          isActive={location.pathname.startsWith(routes.instructor.all)}
        />
        <SidebarLink
          to={routes.employmentApplications.all}
          text={selectedContent[localizationKeys.employmentApplications]}
          icon={EmploymentApplicationsIcon}
          isActive={location.pathname.startsWith(
            routes.employmentApplications.all
          )}
        />
        <div className="my-3 flex items-center justify-between gap-3 text-white ltr:ml-6 rtl:mr-6">
          <div className="flex items-center  gap-3">
            <JobsIcon className="fill-white" size="20px" />
            <span>{selectedContent[localizationKeys.jobs]}</span>
          </div>
          <MdKeyboardArrowDown
            className={`mx-4 cursor-pointer ${
              isViewingJobsDropdown ? "rotate-180" : ""
            }`}
            size="20px"
            onClick={() => setIsViewingJobsDropdown(!isViewingJobsDropdown)}
          />
        </div>
        {isViewingJobsDropdown && (
          <div className="ltr:ml-6 rtl:mr-6">
            <SidebarLink
              to={routes.jobs.active}
              text={selectedContent[localizationKeys.active2]}
              icon={ActiveJobsIcon}
              isActive={location.pathname.startsWith(routes.jobs.active)}
            />
            <SidebarLink
              to={routes.jobs.drafted}
              text={selectedContent[localizationKeys.drafted2]}
              icon={DraftedJobsIcon}
              isActive={location.pathname.startsWith(routes.jobs.drafted)}
            />
            <SidebarLink
              to={routes.jobs.closed}
              text={selectedContent[localizationKeys.archived2]}
              icon={ClosedJobIcon}
              isActive={location.pathname.startsWith(routes.jobs.closed)}
            />
          </div>
        )}
        <SidebarLink
          to={routes.subscribtions.all}
          text={selectedContent[localizationKeys.paying]}
          icon={PaymentIcon}
          isActive={location.pathname.startsWith(routes.subscribtions.all)}
        />
        <SidebarLink
          to={routes.settings}
          text={selectedContent[localizationKeys.settings]}
          icon={SettingsIcon}
          isActive={location.pathname.startsWith(routes.settings)}
        />
        <div className="mt-2 flex items-center">
          <BsGlobe className="fill-white ltr:ml-7 rtl:mr-7" />
          <Dropdown
            className="mx-4 text-white"
            text={
              lang === "ar"
                ? selectedContent[localizationKeys.arabic]
                : selectedContent[localizationKeys.english]
            }
          >
            <Dropdown.Menu>
              {lang === "ar" ? (
                <Dropdown.Item
                  text={"English"}
                  onClick={() => {
                    setLang("en");
                    setIsVisible(false);
                  }}
                />
              ) : (
                <Dropdown.Item
                  text={"العربية"}
                  onClick={() => {
                    setLang("ar");
                    setIsVisible(false);
                  }}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <SidebarLink
          className="mt-3"
          onClick={(e) => {
            e.preventDefault();
            setIsConfirmOpen(true);
          }}
          text={selectedContent[localizationKeys.logout]}
          icon={MdOutlinePowerSettingsNew}
          isActive={location.pathname.startsWith(routes.logout)}
          isLogout={true}
        />
      </Sidebar>
      <Confirm
        closeOnEscape
        className="text-center text-xl font-extrabold text-black "
        cancelButton={selectedContent[localizationKeys.cancelButton]}
        confirmButton={selectedContent[localizationKeys.confirmButton]}
        content={selectedContent[localizationKeys.logoutMessage]}
        size="mini"
        open={isConfirmOpen}
        onCancel={() => setIsConfirmOpen(false)}
        onConfirm={auth.logout}
      />
    </>
  );
};

export default SidebarComponent;

const SidebarLink = ({
  to = "",
  text,
  isActive,
  icon: Icon,
  onClick,
  isLogout,
}) => (
  <div className="relative bg-none ">
    {isActive && (
      <div className="-mt-2 h-4 w-full bg-[#FCFCFC]">
        <div className=" h-4 bg-primary-900 ltr:rounded-br-3xl rtl:rounded-bl-3xl" />
      </div>
    )}
    <Link
      to={to}
      className={`flex items-center gap-4 hover:cursor-pointer ltr:pl-6 rtl:pr-6 ${
        isActive
          ? "bg-[#FCFCFC] text-primary-900 ltr:rounded-l-full rtl:rounded-r-full "
          : ""
      } ${isLogout ? "fixed bottom-0 w-full" : ""}`}
      onClick={onClick}
    >
      {isActive ? (
        <Icon size="20px" className="fill-primary-900 text-primary-900" />
      ) : (
        <Icon size="20px" className="fill-white text-white  " />
      )}
      <p
        className={`mt-3 mb-4 w-full  ${
          isActive ? " text-primary-900" : "text-white"
        }`}
      >
        {text}
      </p>
    </Link>
    {isActive && (
      // <div className="absolute bottom-0 h-4 pt-4 bg-green-300 w-[17rem] rounded-tl-3xl" />
      <div className="-mb-2 h-4 w-full bg-[#FCFCFC]">
        <div className=" h-4 bg-primary-900 ltr:rounded-tr-3xl rtl:rounded-tl-3xl" />
      </div>
    )}
  </div>
);
