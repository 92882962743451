import classNames from "classnames";
import { useState } from "react";
import { AiFillEye } from "react-icons/ai";
import { BsDownload } from "react-icons/bs";
import { generatePath, Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import routes from "../../routes";
import SpContainer from "../container/sp-container";
import ResumeModal from "../instructor-profile/resume-modal";
import TableButton from "./table-button";

const InstructorCard = ({ instructor, modalOpen, priceData }) => {
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const [modal, setModal] = useState({ open: false, loaded: false });
  return (
    <SpContainer
      customBox={true}
      customDimension={true}
      key={instructor._id}
      className={classNames(
        "max-w-lg flex-grow items-center justify-center p-8 md:w-[400px]",
        {
          "bg-white": !instructor.viewed,
          "bg-[#E8F9FFCF]": !!instructor?.viewed,
        }
      )}
    >
      <div className="grid w-full grid-cols-[10rem_1fr] gap-4">
        <div className="col-start-1 col-end-3 row-start-1 row-end-2">
          <Link
            to={{
              pathname: generatePath(routes.instructor.profilePage, {
                instructorId: instructor._id,
              }),
            }}
            className="whitespace-nowrap text-black hover:underline"
          >
            {instructor.name?.[lang] ||
              selectedContent[localizationKeys.instructorName]}
          </Link>
        </div>
        <div className="col-span-1 col-start-1 row-span-1 row-start-2">
          {selectedContent[localizationKeys.eduLevel]}
        </div>
        <div className="col-span-1 col-start-2 row-span-1 row-start-2">
          {instructor.education_levels?.[0].name}
          {instructor.education_levels?.length > 1 ? "..." : ""}
        </div>
        <div className="col-span-1 col-start-1 row-span-1 row-start-3">
          {selectedContent[localizationKeys.subject]}
        </div>
        <div className="col-span-1 col-start-2 row-span-1 row-start-3">
          {instructor.subject?.[0].name}
          {instructor.subject?.length > 1 ? "..." : ""}
        </div>
        <div className="col-span-1 col-start-1 row-span-1 row-start-4">
          {selectedContent[localizationKeys.nationality]}
        </div>
        <div className="col-span-1 col-start-2 row-span-1 row-start-4">
          {instructor.country}
        </div>
        <div className="col-span-1 col-start-1 row-span-1 row-start-5">
          {selectedContent[localizationKeys.cv]}
        </div>
        <div className="col-span-1 col-start-2 row-span-1 row-start-5">
          {!instructor?.viewed ? (
            <div className="col-span-2">
              {/* <Button
                primary
                className="flex w-full  items-center justify-center rounded-md bg-primary-900 px-2  py-2 text-xs text-white hover:shadow-md"
                size="small"
                color="blue"
                // disabled={!d?.instructor?.resume_link}
                onClick={modalOpen}
              >
                {selectedContent[localizationKeys.payToView].replace(
                  "{X}",
                  priceData
                )}
              </Button> */}
            </div>
          ) : (
            <div className="flex flex-row gap-3">
              <div>
                <TableButton
                  onClick={() =>
                    setModal((state) => ({ ...state, open: true }))
                  }
                  disabled={!instructor.resume_link}
                >
                  <AiFillEye className="fill-primary-900 ltr:mr-1 rtl:ml-1" />
                  <span>{selectedContent[localizationKeys.view]}</span>
                </TableButton>
              </div>
              <a
                href={instructor.resume_link}
                target="_blank"
                rel="noopener noreferrer"
                download={(instructor?.name?.en || "CV") + ".pdf"}
              >
                <TableButton disabled={!instructor.resume_link}>
                  <BsDownload className="fill-primary-900 ltr:mr-1 rtl:ml-1" />
                  <span>{selectedContent[localizationKeys.download]}</span>
                </TableButton>
              </a>
            </div>
          )}
        </div>
      </div>
      <ResumeModal modal={modal} setModal={setModal} instructor={instructor} />
    </SpContainer>
  );
};

export default InstructorCard;
