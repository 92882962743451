import React, { useState } from "react";
import AddAndEditInfo from "./Add-Edit-Info";
import { useFormik } from "formik";
import * as Yup from "yup";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import useAxios from "../../../lib/use-axios";
import { useLanguage } from "../../../lib/language-context";
import content from "../../../localization/content";
import localizationKeys from "../../../localization/localization-keys";
import Swal from "sweetalert2";

export default function SchoolInformation() {
  const [isLoading, setIsLoading] = useState(false);
  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];
  const [inputs, setInputs] = useState([
    { name: { ar: "", en: "" }, phone: "" },
  ]);
  const [dataOfProgram, setDataOfProgram] = useState(null);
  const [open, setOpen] = useState(false);
  const schoolId = localStorage.getItem("id");

  const validationSchema = Yup.object().shape({
    teachersInfo: Yup.array().of(
      Yup.object().shape({
        name: Yup.object().shape({
          ar: Yup.string().required("Arabic name is required"),
          en: Yup.string().required("English name is required"),
        }),
        phone: Yup.string().matches(
          /^((\+?966|0)?\s?\d{3}\s?\d{3}\s?\d{3})$/,
          "Phone number is not valid"
        ),
      })
    ),
    numberOfStudents: Yup.string(),
    boysStudents: Yup.string(),
    girlsStudents: Yup.string(),
    educationStage: Yup.array().of(Yup.string()),
    NumberOfServings: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      teachersInfo: dataOfProgram?.teachersInfo || [
        { name: { ar: "", en: "" }, phone: "" },
      ],
      numberOfStudents: dataOfProgram?.NumberOfStudents || "",
      boysStudents: dataOfProgram?.NumberOfBoys || "",
      girlsStudents: dataOfProgram?.NumberOfGirls || "",
      educationStage: dataOfProgram?.educationStage || [],
      NumberOfServings: dataOfProgram?.NumberOfServings || "",
    },
    // validationSchema,
    onSubmit: handleSave,
  });
  async function handleSave(values) {
    const finalValues = {
      teachersInfo: values.teachersInfo.map((teacher, index) => {
        const teacherFromData = dataOfProgram?.teachersInfo[index] || {};

        return {
          name: {
            ar: teacher.name.ar || teacherFromData.name?.ar || "",
            en: teacher.name.en || teacherFromData.name?.en || "",
          },
          phone: teacher.phone || teacherFromData.phone || "",
        };
      }),
      numberOfStudents:
        values.numberOfStudents || dataOfProgram?.numberOfStudents || "",
      boysStudents: values.boysStudents || dataOfProgram?.boysStudents || "",
      girlsStudents: values.girlsStudents || dataOfProgram?.girlsStudents || "",
      educationStage:
        values.educationStage || dataOfProgram?.educationStage || [],
      NumberOfServings:
        values.NumberOfServings || dataOfProgram?.NumberOfServings || "",
    };
    try {
      setIsLoading(true);
      const response = await authAxios.patch(
        `${api.programInformation.edit(schoolId)}`,
        finalValues
      );
      setDataOfProgram(response?.data?.data?.programInfo);
      setOpen(false);
      setIsLoading(false);
      Swal.fire({
        title: selectedContent[localizationKeys.educationProgramUpdated],
        timer: 1000,
        icon: "success",
      });
    } catch (e) {
      console.error("Failed to save data", e);
    } finally {
      setIsLoading(false);
    }
  }

  const addRowField = () => {
    setInputs([...inputs, { name: { ar: "", en: "" }, phone: "" }]);
    formik.setFieldValue("teachersInfo", [
      ...formik.values.teachersInfo,
      { name: { ar: "", en: "" }, phone: "" },
    ]);
  };

  const removeRowField = (index) => {
    if (formik.values.teachersInfo.length === 1) return;
    const updatedTeachers = [...formik.values.teachersInfo];
    updatedTeachers.splice(index, 1);
    formik.setFieldValue("teachersInfo", updatedTeachers);
  };
  const { run: getSchool } = useAxios({});
  React.useEffect(() => {
    getSchool(authAxios.get(`${api.schools.schools}/${schoolId}`)).then(
      (progInfo) => {
        setDataOfProgram(progInfo?.data?.data?.programInfo);
      }
    );
  }, [getSchool, schoolId]);
  return (
    <section className="rounded-lg border p-4">
      <div className="my-4 flex flex-wrap items-center justify-between border-b pb-4">
        <h3 className=" text-primary-900">
          {selectedContent[localizationKeys.programInfo]} :
        </h3>
        <div className="flex w-full items-center gap-3 sm:w-auto">
          <AddAndEditInfo
            inputs={inputs}
            setInputs={setInputs}
            data={dataOfProgram}
            setData={setDataOfProgram}
            formik={formik}
            addRowField={addRowField}
            removeRowField={removeRowField}
            open={open}
            setOpen={setOpen}
          />
        </div>
      </div>
      <div className="w-4/5 rounded-lg border p-4 md:mx-auto">
        <>
          {!dataOfProgram ? (
            <div className="flex flex-col justify-between lg:mb-0 lg:flex-row">
              <h4 className="text-primary-900">
                {selectedContent[localizationKeys.teachersName]}:
                <span className="text-black"> </span>
              </h4>
              <h4 className="text-primary-900">
                {selectedContent[localizationKeys.teachersPhoneNumber]}:
                <span className="text-black"> </span>
              </h4>
            </div>
          ) : (
            dataOfProgram?.teachersInfo.map((teacher, index) => (
              <div
                key={index}
                className="my-2 flex flex-col justify-between lg:mb-0 lg:flex-row"
              >
                <h4 className="text-primary-900">
                  ({index + 1}) {selectedContent[localizationKeys.teachersName]}
                  :
                  <span className="text-black">
                    {" "}
                    {document.getElementsByTagName("html")[0].lang === "ar"
                      ? teacher?.name.ar
                      : teacher?.name.en}
                  </span>
                </h4>
                <h4 className="text-primary-900">
                  {selectedContent[localizationKeys.teachersPhoneNumber]}:
                  <span className="text-black"> {teacher?.phone}</span>
                </h4>
              </div>
            ))
          )}
        </>

        <div className="flex flex-col flex-wrap justify-between py-2 lg:flex-row lg:items-center">
          <h4 className="text-primary-900">
            {selectedContent[localizationKeys.educationalStage]}:{" "}
            <span className="text-black">
              {dataOfProgram?.educationStage.join(" , ")}
            </span>
          </h4>
          <h4 className="text-primary-900">
            {selectedContent[localizationKeys.numberOfServings]}:{" "}
            <span className="text-black">
              {dataOfProgram?.NumberOfServings}
            </span>
          </h4>
        </div>
        <div className="flex flex-col flex-wrap justify-between py-2 lg:flex-row lg:items-center">
          <h4 className="text-primary-900">
            {selectedContent[localizationKeys.numberOfStudents]}:
            <span className="text-black">
              {" "}
              {dataOfProgram?.numberOfStudents}
            </span>
          </h4>
          <h4 className="text-primary-900">
            {selectedContent[localizationKeys.numberOfBoys]}:
            <span className="text-black"> {dataOfProgram?.boysStudents}</span>
          </h4>
          <h4 className="text-primary-900">
            {selectedContent[localizationKeys.numberOfGirls]}:{" "}
            <span className="text-black"> {dataOfProgram?.girlsStudents}</span>
          </h4>
        </div>
      </div>
    </section>
  );
}
