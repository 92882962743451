import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";
import api from "../../../api";

import { authAxios } from "../../../config/axios-config";
import useAxios from "../../../lib/use-axios";

import SchoolProfile from "./SchoolProfile";
import { IoArrowBackOutline } from "react-icons/io5";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useLanguage } from "../../../lib/language-context";
import content from "../../../localization/content";
import localizationKeys from "../../../localization/localization-keys";
import { IoMdClose } from "react-icons/io";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import routes from "../../../routes";
const SchoolDetails = () => {
  const [imageUrls, setImageUrls] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(null);

  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];
  const history = useHistory();

  const {schoolId} = useParams();
  
  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % imageUrls.length;
    setCurrentIndex(nextIndex);
    setCurrentImage(imageUrls[nextIndex]?.fileLink);
  };

  const handleImageChange = (index) => {
    const image = imageUrls[index]?.fileLink;
    setCurrentImage(image);
    setShowImage(true);
  };
  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + imageUrls.length) % imageUrls.length;
    setCurrentIndex(prevIndex);
    setCurrentImage(imageUrls[prevIndex]?.fileLink);
  };

  const fetchThreeImages = async () => {
    try {
      const response = await authAxios.get(api.schoolAlbum.get(schoolId, 0, 3));
      setImageUrls(response?.data?.data?.albums);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const {
    run: getSchool,
    data: school,
    isLoading: isGettingSchool,
  } = useAxios({});

  useEffect(() => {
    getSchool(authAxios.get(`${api.schools.schools}/${schoolId}`)).then(
      () => {}
    );
  }, [getSchool, schoolId]);

  useEffect(() => {
    fetchThreeImages();
  }, [schoolId]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") setShowImage(false);
      if (e.key === "ArrowRight") handleNext();
      if (e.key === "ArrowLeft") handlePrev();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex, imageUrls]);
  return (
    <div className="px-8">
      {isGettingSchool ? (
        <Loader active={true} size="small" className="mt-4" />
      ) : (
        <>
          <div
            onClick={() => history.goBack()}
            className="my-4 w-fit cursor-pointer rounded-full border border-primary-900 px-3 py-3 text-primary-900 transition-all duration-300 hover:bg-primary-900 hover:text-white rtl:rotate-180"
          >
            <IoArrowBackOutline className="flex items-center justify-center " />
          </div>
          <div className=" mx-auto mb-8 h-full  rounded-2xl bg-white p-6 shadow-sm ring-1 ring-[#E3E3E3]">
            <SchoolProfile school={school} />
          </div>
          <div
            className={`my-4 mt-4 flex flex-wrap items-center rounded-md ${
              imageUrls?.length > 0 ? "border border-gray-200" : ""
            } p-4"`}
          >
            {imageUrls?.length > 0 ? (
              <div className="flex w-full flex-wrap items-center">
                <div
                  className={`fixed inset-0 z-[200] bg-black bg-opacity-75 ${
                    showImage ? "visible" : "hidden"
                  }`}
                >
                  <button
                    onClick={() => setShowImage(false)}
                    className="absolute right-6 top-4 rounded-md bg-red-400 p-3 text-white hover:bg-red-500"
                  >
                    <IoMdClose />
                  </button>
                  <button
                    onClick={handleNext}
                    className="absolute right-4 top-1/2 -translate-y-1/2 rounded-md bg-white p-2"
                  >
                    <FaAngleRight />
                  </button>
                  <button
                    onClick={handlePrev}
                    className="absolute left-4 top-1/2 -translate-y-1/2 rounded-md bg-white p-2"
                  >
                    <FaAngleLeft />
                  </button>
                  <div className="mx-auto flex items-center justify-center">
                    <img
                      className="h-[100vh] w-[90vw] rounded-lg border border-gray-300 object-contain shadow-lg"
                      src={currentImage}
                      alt="Expanded View"
                    />
                  </div>
                </div>
                {imageUrls?.slice(0, 3).map((url, index) => (
                  <>
                    <div
                      onClick={() => handleImageChange(index)}
                      key={index}
                      className="w-full cursor-pointer p-4 sm:w-1/3 md:w-1/4"
                    >
                      <div className="group relative my-4 mx-auto overflow-hidden rounded-lg p-4 shadow-lg transition-transform">
                        <img
                          src={url?.fileLink}
                          alt={`Uploaded ${index + 1}`}
                          className="w-full rounded-md bg-cover bg-center"
                        />
                      </div>
                    </div>
                  </>
                ))}

                {imageUrls?.length === 3 && (
                  <div
                  key={imageUrls?._id}
                    className="mx-auto cursor-pointer rounded-lg"
                    style={{
                      backgroundImage: `url(${imageUrls[0]?.fileLink})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                    onClick={() => {
                      history.push(routes.viewImages.viewAllImagesInSchoolDetails);
                    }}
                  >
                    <div className="mt-2 mr-2 flex h-48 w-48 items-center justify-center rounded-lg bg-gray-200 bg-opacity-75 text-primary-900 transition-all duration-300 hover:bg-gray-300 hover:bg-opacity-75">
                      <h3>{selectedContent[localizationKeys.viewAllAlbum]}</h3>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <p className="text-gray-500">
                {selectedContent[localizationKeys.noImagesUploaded]}
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SchoolDetails;
