const api = {
  auth: {
    login: "/schools/sign-in",
    signup: "/schools/sign-up",
    refreshToken: "/schools/refreshToken",
    forgotPassword: "/schools/forgot-password",
    resetPassword: "/schools/reset-password",
    resendEmail: "/schools/resend-email-verification",
    resendEmailLink: "/schools/resend-email",
  },
  schools: {
    schools: "/schools",
  },
  schoolsDetails: {
    school: "/schools/:schoolId",
  },
  city: { all: "/cities" },
  educationalOffices: {
    all: "/education-centers",
  },
  educationalLevels: { all: "/education-levels" },
  vacancies: {
    all: (school = ":school") => `/vacancies?school=${school}`,
    delete: (vacancy = ":vacancy") => `/vacancies/${vacancy}`,
    archive: (vacancy = ":vacancy") => `/vacancies/${vacancy}/archive`,
    activate: (vacancy = ":vacancy") => `/vacancies/${vacancy}/activate`,
    post: "/vacancies",
    get: (vacancy = ":vacancy") => `/vacancies/${vacancy}`,
    edit: (vacancy = ":vacancy") => `/vacancies/${vacancy}`,
    workPlaces: "/vacancies/work-places",
    workTypes: "/vacancies/work-types",
  },
  applicants: {
    all: (vacancy = ":vacancy") => `/applied-vacancies?vacancy=${vacancy}`,
    changeState: (vacancy = ":vacancy") => `/applied-vacancies/${vacancy}`,
    get: (vacancy = ":vacancy") => `/applied-vacancies/${vacancy}`,
  },
  tracks: { all: "/secondary-tracks" },
  subjects: { all: "/subjects" },
  instructor: {
    profile: (instructor = ":instructor") => `/instructors/${instructor}`,
  },
  subscribtion: {
    checkStatus: "/subscriptions/check-payment-status",
    all: (school = ":school") => `/schools/${school}/subscriptions`,
  },
  notifications: {
    all: "/schools/notifications",
  },
  sendPaymentPrices: "payment-prices",
  schoolAlbum: {
    get: (schoolId = ":schoolId", skip = ":skip", limit = ":limit") =>
      `/schools/${schoolId}/album?skip=${skip}&limit=${limit}`,
    add: (schoolId = ":schoolId") => `/schools/${schoolId}/album`,
    delete: (schoolId = ":schoolId", imgId = ":id") =>
      `/schools/${schoolId}/album/${imgId}`,
  },
  programInformation: {
    edit: (schoolId = ":schoolId") => `/schools/${schoolId}/program-info`,
  },
  BreakingLimits: {
    getMaterial: (schoolId = ":schoolId", lang = ":lang") =>
      `/schools/${schoolId}/${lang}`,
    viewInterestedSchools: (searchName = ":searchName") =>
      `/schools/downloads?search=${searchName}`,
  },
};

export default api;
