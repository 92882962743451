import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useLanguage } from "../../lib/language-context";
import content from "../../localization/content";
import localizationKeys from "../../localization/localization-keys";
import { MdOutlineCancel } from "react-icons/md";
import { BsArrowLeftCircle, BsInfoCircle, BsLockFill } from "react-icons/bs";
import useAxios from "../../lib/use-axios";
import { authAxios } from "../../config/axios-config";
import api from "../../api";
import PublishmentsNotFoundIcon from "../../assets/icons/publishments-notFound-icon";
import { Button, Confirm, Loader, Message, Popup } from "semantic-ui-react";
import { truncateString } from "../../utils/string";
import moment from "moment";
import BinIcon from "../../assets/icons/bin-icon";
import EditIcon from "../../assets/icons/edit-icon";
import useFilter from "../../lib/use-filter";
import PaginationComponent from "../../components/shared/pagination";
import ViewVacancyDetails from "../vacancy/view-vacancy-details";
import { useLocation } from "react-router-dom";
import AddVacancy from "../vacancy/add-vacancy";
import "moment/locale/en-au";
import useMediaQuery from "../../components/hooks/use-media-query";

const MainPage = () => {
  moment.locale("en-au");
  const [lang] = useLanguage();
  const selectedContent = content[lang];
  const id = localStorage.getItem("id");
  const [viewVancanciesPopup, setVancanciesPopup] = React.useState(true);

  const [limit] = React.useState("4");
  const [totalPages, setTotalPages] = React.useState(0);
  const [vacancies, setVacancies] = React.useState([]);
  const [page, setPage] = useFilter("page", 1);

  const [isViewingVacancy, setIsViewingVacancy] = React.useState(false);
  const [vacancy, setVacancy] = React.useState("");
  const [forceReload, setForceReload] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isClosingVacancy, setIsClosingVacancy] = React.useState(false);
  const [isAddingVacancy, setIsAddingVacancy] = React.useState(false);
  const [isEditingVacancy, setIsEditingVacancy] = React.useState(false);
  const [isPublishingVacancy, setIsPublishingVacancy] = React.useState(false);
  const [priceData, setPriceData] = React.useState();

  const location = useLocation();
  const queryString = require("query-string");
  const parsed = queryString.parse(location.search);
  const isMobile = useMediaQuery("(max-width: 776px)");

  const {
    run: getSchool,
    data: school,
    isLoading: isGettingSchool,
  } = useAxios({});
  const { run: getVacancies, isLoading: isGettingVacancies } = useAxios({});
  const { run: deleteVacancy } = useAxios({ data: [] });
  const { run: closeVacancy } = useAxios({ data: [] });
  const { run: publish } = useAxios({ data: [] });

  React.useEffect(() => {
    getSchool(authAxios.get(`${api.schools.schools}/${id}`)).then(() => {});
  }, [getSchool, id, forceReload]);

  React.useEffect(() => {
    if (page < 1) setPage(1);
    if (page && page !== "undefined")
      getVacancies(
        authAxios.get(
          `${api.vacancies.all(id)}&limit=${limit}&page=${parsed?.page}`
        )
      )
        .then(({ data }) => {
          setTotalPages(data?.totalPages);
          // if (page > data?.totalPages) setPage(1);
          if (page > data?.totalPages && data?.totalPages !== 0) setPage(1);
          setVacancies(data);
        })
        .catch((e) => e?.errors?.map((s) => toast.error(s.message)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVacancies, limit, forceReload, id, parsed?.page]);

  const DeleteVacancy = (id) => {
    deleteVacancy(authAxios.delete(`${api.vacancies.delete(id)}`))
      .then(() => {
        toast.success(selectedContent[localizationKeys.afterDeletionMessage]);
        setIsDeleting(false);
        setForceReload((p) => !p);
        setVacancy("");
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  const CloseVacancy = (id) => {
    closeVacancy(
      authAxios.patch(`${api.vacancies.archive(id)}`, {
        status: "archived",
      })
    )
      .then(() => {
        toast.success(
          selectedContent[localizationKeys.vacancyIsClosedSuccessfully]
        );
        setIsClosingVacancy(false);
        setForceReload((p) => !p);
        setVacancy("");
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  const publishVacancy = (id) => {
    publish(authAxios.patch(`${api.vacancies.activate(id)}`))
      .then(({ data }) => {
        if (data?.data?.InvoiceURL) {
          window.open(data?.data?.InvoiceURL);
          setForceReload((p) => !p);
          setIsPublishingVacancy(false);
        } else {
          setForceReload((p) => !p);
          setVacancy("");
          setIsPublishingVacancy(false);
          toast.success(
            selectedContent[localizationKeys.publishedSuccessfully]
          );
        }
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  };

  const { run: getrun, isLoading: getisLoading } = useAxios();
  useEffect(() => {
    getrun(authAxios.get(api.sendPaymentPrices))
      .then(({ data }) => {
        setPriceData(data?.data);
      })
      .catch((e) => {
        e?.errors?.map((s) => toast.error(s.message));
      });
  }, [getrun]);

  return (
    <div className="px-8">
      {!window.navigator.onLine && (
        <Message error content={selectedContent[localizationKeys.noInternet]} />
      )}
      {isGettingSchool || isGettingVacancies ? (
        <Loader active={true} size="small" className="mt-8" />
      ) : (
        <div className="mb-10 min-h-[35rem] rounded-xl bg-white p-4 px-8 shadow-sm">
          {/* {viewVancanciesPopup && school?.data?.vacancies_limit > 0 && (
            <div
              className="flex h-14 items-center justify-between rounded-xl
                      bg-[#C5E6F326] px-4 text-primary-900 ring-1 ring-[#C5E6F3CC]"
            >
              <div>
                <span>{selectedContent[localizationKeys.YouHave]}</span>
                <span className="ltr:ml-1 rtl:mr-1">
                  {school?.data?.vacancies_limit}
                </span>
                <span>
                  {selectedContent[
                    localizationKeys.freePublicationsLeft
                  ].replace("{X}", priceData?.vacancyPrice)}
                </span>
              </div>
              <MdOutlineCancel
                size="20px"
                className="cursor-pointer"
                onClick={() => setVancanciesPopup(false)}
              />
            </div>
          )} */}
          <div
            className="mt-4 flex h-16 items-center justify-between rounded-xl px-4 text-[#909090]
           ring-1 ring-[#B8B8B8] hover:cursor-pointer"
            onClick={() => {
              window.navigator.onLine && setIsAddingVacancy(true);
            }}
          >
            <span className="">
              {`${selectedContent[localizationKeys.addNewVacancy]} ${
                priceData?.vacancyPrice
              } ${lang === "en" ? "saudi riyals" : "ريال سعودي"}`}
            </span>
            <BsArrowLeftCircle
              size="20px"
              className="cursor-pointer ltr:rotate-180"
            />
          </div>
          <div className="mt-8">
            <div className="text-lg font-black">
              {selectedContent[localizationKeys.pastPublications]}
            </div>
            {isGettingVacancies ? (
              <Loader active={true} size="small" className="mt-8" />
            ) : vacancies?.data?.length > 0 ? (
              <div className="relative pb-16">
                <div className="mt-4 flex flex-wrap items-center gap-4">
                  {vacancies.data?.map((d) => (
                    <div
                      className={`relative ring-1
                      ${
                        d?.isSchoolNameHidden
                          ? "ring-[#909090A6]"
                          : "ring-primary-300"
                      } 
                    ${
                      isMobile
                        ? "h-[26rem] w-[20rem] text-sm"
                        : "h-[22.5rem] w-[31rem] text-base"
                    }  rounded-lg p-2 px-4`}
                    >
                      <div className="flex items-center justify-between">
                        <div className="mt-4 flex items-center font-bold">
                          {truncateString(d?.title, 35)}
                          {d?.isSchoolNameHidden && (
                            <div className="mx-1 flex items-center rounded-3xl bg-[#E6E6E6] p-1 text-sm font-normal text-[#909090]">
                              {selectedContent[localizationKeys.hiddenIdentity]}
                              <Popup
                                trigger={<BsInfoCircle className="mx-1" />}
                                position="top right"
                                className="w-60 rounded-xl border-primary-300 p-2 before:border-none before:bg-transparent before:shadow-none "
                              >
                                <Popup.Content className="text-center text-sm text-[#909090]">
                                  {
                                    selectedContent[
                                      localizationKeys.hiddenIdentityInfo
                                    ]
                                  }
                                </Popup.Content>
                              </Popup>
                            </div>
                          )}
                        </div>
                        {d?.status === "drafted" && (
                          <div className="mx-1 mt-4 rounded-3xl bg-[#E2C6061F] px-2 text-sm text-[#E2C606]">
                            {selectedContent[localizationKeys.drafted]}
                          </div>
                        )}
                        {d?.status === "active" && (
                          <div className="mx-1 mt-4 rounded-3xl bg-[#34C00A1F] px-2 text-sm text-[#34C00A]">
                            {selectedContent[localizationKeys.active]}
                          </div>
                        )}
                        {d?.status === "archived" && (
                          <div className="mx-1 mt-4 rounded-3xl bg-[#DB00001A] px-2 py-1 text-sm text-[#DB0000]">
                            {selectedContent[localizationKeys.archived]}
                          </div>
                        )}
                      </div>
                      <div className="mt-2 flex items-center">
                        <span className="my-2 flex items-center gap-1 text-[#A0A0A0]">
                          <span>{moment(d?.createdAt).format("L")}</span>,
                          <span>{moment(d?.createdAt).format("LT")}</span>
                        </span>
                        <div className="mx-1 rounded-3xl bg-[#C5E6F326] px-2 py-1 text-sm text-primary-900 ltr:ml-4 rtl:mr-4">
                          {lang === "ar"
                            ? d?.work_type?.name?.ar
                            : d?.work_type?.name?.en}
                        </div>
                        <div className="mx-0.5 w-max rounded-3xl  bg-[#C5E6F326] px-2 py-1 text-sm text-primary-900">
                          {lang === "ar"
                            ? d?.work_place?.name?.ar
                            : d?.work_place?.name?.en}
                        </div>
                      </div>
                      <div className="my-3 grid grid-cols-3">
                        <div className=" text-[#A0A0A0]">
                          {selectedContent[localizationKeys.educationalOffice]}:
                        </div>
                        <div className="col-span-2">
                          {d?.education_center?.name} -{" "}
                          {d?.education_center?.gender}
                        </div>
                      </div>
                      <div className="my-3 grid grid-cols-3">
                        <div className=" text-[#A0A0A0]">
                          {selectedContent[localizationKeys.eduLevel]}:
                        </div>
                        <div className="col-span-2">
                          {d?.education_levels?.name}
                        </div>
                      </div>
                      {d?.secondary_tracks && (
                        <div className="my-3 grid grid-cols-3">
                          <div className=" text-[#A0A0A0]">
                            {selectedContent[localizationKeys.track]}:
                          </div>
                          <div className="col-span-2">
                            {d?.secondary_tracks?.name}
                          </div>
                        </div>
                      )}
                      <div className="my-3 grid grid-cols-3">
                        <div
                          className={`  ${
                            isMobile ? "" : "w-max"
                          } text-[#A0A0A0]`}
                        >
                          {selectedContent[localizationKeys.typeOfInstructors]}:
                        </div>
                        <div className="col-span-2 ltr:ml-6 rtl:mr-6">
                          {d?.gender}
                        </div>
                      </div>
                      <div className="my-3 grid grid-cols-3">
                        <span className=" text-[#A0A0A0]">
                          {selectedContent[localizationKeys.salary]}:
                        </span>
                        {d?.salary ? (
                          <span>
                            <span>{d?.salary}</span>
                            <span className="mx-1">
                              {selectedContent[localizationKeys.RS]}
                            </span>
                          </span>
                        ) : (
                          <span className="w-max">
                            {
                              selectedContent[
                                localizationKeys.determinedAtTheInterview
                              ]
                            }
                          </span>
                        )}
                      </div>
                      <div className="absolute bottom-0 mb-4 flex w-full items-center ltr:pr-6 rtl:pl-6">
                        {d?.status === "drafted" && (
                          <>
                            <Button
                              primary
                              size={`${isMobile ? "small" : "large"}`}
                              type="button"
                              circular
                              icon
                              className="bg-[#DA595921]"
                              onClick={() => {
                                setVacancy(d);
                                setIsDeleting(true);
                              }}
                            >
                              <BinIcon />
                            </Button>
                            <Button
                              primary
                              size={`${isMobile ? "small" : "large"}`}
                              type="button"
                              circular
                              icon
                              className="bg-[#C5E6F36E] ltr:mr-4 rtl:ml-4"
                              onClick={() => {
                                setVacancy(d);
                                setIsEditingVacancy(true);
                              }}
                            >
                              <EditIcon />
                            </Button>
                          </>
                        )}
                        {d?.status === "active" && (
                          <Button
                            primary
                            className={`w-36 rounded-md  bg-white text-warning ring-1
                             ring-warning rtl:ml-4 ${isMobile ? "p-2" : ""}`}
                            size={`${isMobile ? "small" : "large"}`}
                            onClick={() => {
                              setVacancy(d);
                              setIsClosingVacancy(true);
                            }}
                          >
                            {selectedContent[localizationKeys.close]}
                          </Button>
                        )}

                        <Button
                          primary
                          className={`w-full rounded-md bg-white text-primary-900 ring-1 ring-primary-900
                          ${isMobile ? "p-2" : ""}`}
                          size={`${isMobile ? "small" : "large"}`}
                          onClick={() => {
                            setIsViewingVacancy(true);
                            setVacancy(d);
                          }}
                        >
                          {selectedContent[localizationKeys.more]}
                        </Button>
                        {d?.status === "drafted" && (
                          <Button
                            primary
                            className={`mx-2 w-full rounded-md ring-1 ring-primary-900 ${
                              isMobile ? "p-2" : ""
                            }`}
                            size={`${isMobile ? "small" : "large"}`}
                            onClick={() => {
                              setVacancy(d);
                              setIsPublishingVacancy(true);
                            }}
                          >
                            {selectedContent[localizationKeys.publish]}
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex items-center justify-center">
                  <PaginationComponent
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                    className="absolute bottom-0 rtl:flex-row-reverse"
                  />
                </div>
              </div>
            ) : (
              <div className="mb-16">
                <div className="mt-8 flex items-center justify-center ">
                  <PublishmentsNotFoundIcon className="h-44 w-64" />
                </div>
                <div className="mt-8 flex items-center justify-center text-lg font-semibold">
                  {selectedContent[localizationKeys.thereAreNoPublications]}.
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <ViewVacancyDetails
        isOpen={isViewingVacancy}
        onClose={() => {
          setIsViewingVacancy(false);
          setVacancy("");
        }}
        vacancy={vacancy}
        school={school}
        reload={() => setForceReload((p) => !p)}
        isEditingVacancy={isEditingVacancy}
        setIsEditingVacancy={setIsEditingVacancy}
      />
      <AddVacancy
        isOpen={isAddingVacancy || isEditingVacancy}
        onClose={() => {
          setIsAddingVacancy(false);
          setIsEditingVacancy(false);
          setVacancy("");
          setIsViewingVacancy(false);
        }}
        reload={() => setForceReload((p) => !p)}
        isEditingVacancy={isEditingVacancy}
        vacancy={vacancy}
        school={school}
      />
      <Confirm
        closeOnEscape
        className="text-center text-xl font-extrabold text-black "
        cancelButton={selectedContent[localizationKeys.no]}
        confirmButton={selectedContent[localizationKeys.yesDeleteVacancy]}
        content={
          <div className="p-8">
            <div className="text-base">
              {selectedContent[localizationKeys.deleteVacancy]}
            </div>
            <div className="mx-auto mt-4 w-80 text-base font-normal text-greyText">
              {selectedContent[localizationKeys.deleteVacancyNote]}
            </div>
          </div>
        }
        size="tiny"
        open={isDeleting}
        onCancel={() => setIsDeleting(false)}
        onConfirm={() => DeleteVacancy(vacancy?._id)}
      />
      <Confirm
        closeOnEscape
        className="text-center text-xl font-extrabold text-black "
        cancelButton={selectedContent[localizationKeys.no]}
        confirmButton={selectedContent[localizationKeys.yesCloseVacancy]}
        content={
          <div className="p-8">
            <div className="text-base">
              {selectedContent[localizationKeys.closeVacancyTitle]}
            </div>
            <div className="mx-auto mt-4 w-80 text-base font-normal text-greyText">
              {selectedContent[localizationKeys.closeVacancyNote]}
            </div>
          </div>
        }
        size="tiny"
        open={isClosingVacancy}
        onCancel={() => setIsClosingVacancy(false)}
        onConfirm={() => CloseVacancy(vacancy?._id)}
      />
      <Confirm
        closeOnEscape
        className="text-center text-xl font-extrabold text-black "
        cancelButton={selectedContent[localizationKeys.no]}
        confirmButton={selectedContent[localizationKeys.publish]}
        content={selectedContent[localizationKeys.publishVacancyTitle]}
        size="tiny"
        open={isPublishingVacancy}
        onCancel={() => setIsPublishingVacancy(false)}
        onConfirm={() => publishVacancy(vacancy?._id)}
      />
    </div>
  );
};

export default MainPage;
