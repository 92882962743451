import React, { useEffect, useRef, useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa";
import { FaAngleLeft } from "react-icons/fa";
import Swal from "sweetalert2";
import uploadIcon from "../../../assets/pictures/upload-image.png";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import { useLanguage } from "../../../lib/language-context";
import content from "../../../localization/content";
import localizationKeys from "../../../localization/localization-keys";
import { useHistory } from "react-router-dom";
import routes from "../../../routes";
import { Loader } from "semantic-ui-react";
export default function ImageUpload() {
  const history = useHistory();
  const [lang, setLang] = useLanguage();
  const selectedContent = content[lang];
  const schoolId = localStorage.getItem("id");
  const [imageUrls, setImageUrls] = useState([]);
  const [currentImage, setCurrentImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const inputRef = useRef();
  const maxImages = 3;
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageChange = (index) => {
    const image = imageUrls[index]?.fileLink;
    setCurrentImage(image);
    setShowImage(true);
  };

  const fetchThreeImages = async () => {
    try {
      const response = await authAxios.get(api.schoolAlbum.get(schoolId, 0, 3));
      setImageUrls(response?.data?.data?.albums);
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % imageUrls.length;
    setCurrentIndex(nextIndex);
    setCurrentImage(imageUrls[nextIndex]?.fileLink);
  };

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + imageUrls.length) % imageUrls.length;
    setCurrentIndex(prevIndex);
    setCurrentImage(imageUrls[prevIndex]?.fileLink);
  };
  const handleImageUpload = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  const deleteImage = (index) => {
    Swal.fire({
      title: selectedContent[localizationKeys.deleteImageTitle],
      text: selectedContent[localizationKeys.deleteImageText],
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: selectedContent[localizationKeys.deleteImageConfirm],
      cancelButtonText: selectedContent[localizationKeys.cancelButton],
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setIsLoading(true);
          const imgId = imageUrls?.[index]?._id;
          const response = await authAxios.delete(
            api.schoolAlbum.delete(schoolId, imgId)
          );
          setIsLoading(false);
          setImageUrls((prev) => prev.filter((_, i) => i !== index));
          Swal.fire({
            timer: 1000,
            text: selectedContent[localizationKeys.deletedImageText],
            icon: "success",
          });
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      }
    });
  };

  const uploadImageDisplay = async (e) => {
    if (inputRef.current.length === 0) return;
    const uploadFile = Array.from(e.target.files);
    const totalFiles = uploadFile.length;

    if (totalFiles > maxImages) {
      Swal.fire({
        icon: "warning",
        title: selectedContent[localizationKeys.limitUploadImageTitle],
        text: selectedContent[localizationKeys.limitUploadImageText],
        buttonsStyling: false,
        confirmButtonText:
          selectedContent[localizationKeys.limitUploadImageConfirm],
      });
      inputRef.current.value = null;
      return;
    }
    inputRef.current.file = null;
    const formData = new FormData();
    uploadFile.forEach((file) => {
      formData.append("image", file);
    });
    try {
      setIsLoading(true);
      const response = await authAxios.post(
        api.schoolAlbum.add(schoolId),
        formData
      );
      setImageUrls((prev) => [...prev, ...response?.data?.data]);
      setIsLoading(false);
      Swal.fire({
        icon: "success",
        title: selectedContent[localizationKeys.uploadImageSuccessTitle],
        buttonsStyling: false,
        timer: 1000,
      });
    } catch (e) {
      console.log(e, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchThreeImages();
  }, [schoolId]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") setShowImage(false);
      if (e.key === "ArrowRight") handleNext();
      if (e.key === "ArrowLeft") handlePrev();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex, imageUrls]);
  return (
    <section className="my-4">
      <form className="mt-4">
        <label htmlFor="uploadImg" className="font-semibold text-primary-900">
          {selectedContent[localizationKeys.uploadImage]}:
        </label>
        <div className="my-4">
          <input
            className="hidden cursor-pointer text-transparent"
            id="uploadImg"
            type="file"
            accept="image/*"
            ref={inputRef}
            onChange={uploadImageDisplay}
            multiple
          />
          <button onClick={handleImageUpload} className=" w-[5rem]">
            {isLoading ? (
              <div className="relative mb-10">
                <Loader active={true} size="small" className="mt-4" />
              </div>
            ) : (
              <img className="w-full" src={uploadIcon} alt="uploadIcon" />
            )}
          </button>
        </div>
      </form>
      <div
        className={`flex flex-wrap items-center rounded-md p-4 ${
          imageUrls?.length > 0 ? "border border-gray-200" : ""
        }`}
      >
        {imageUrls?.length > 0 ? (
          <div className="flex w-full flex-wrap items-center">
            <div
              className={`fixed inset-0 z-[200] bg-black bg-opacity-75 ${
                showImage ? "visible" : "hidden"
              }`}
            >
              <button
                onClick={() => setShowImage(false)}
                className="absolute right-6 top-4 rounded-md bg-red-400 p-3 text-white hover:bg-red-500"
              >
                <IoMdClose />
              </button>
              <button
                onClick={handleNext}
                className="absolute right-4 top-1/2 -translate-y-1/2 rounded-md bg-white p-2"
              >
                <FaAngleRight />
              </button>
              <button
                onClick={handlePrev}
                className="absolute left-4 top-1/2 -translate-y-1/2 rounded-md bg-white p-2"
              >
                <FaAngleLeft />
              </button>
              <div className="mx-auto flex items-center justify-center">
                <img
                  className="h-[100vh] w-[90vw] rounded-lg border border-gray-300 object-contain shadow-lg"
                  src={currentImage}
                  alt="Expanded View"
                />
              </div>
            </div>
            {imageUrls?.slice(0, 3).map((url, index) => (
              <div
                onClick={() => handleImageChange(index)}
                key={index}
                className="w-full cursor-pointer p-4 sm:w-1/3 md:w-1/4"
              >
                <div className="group relative my-4 mx-auto overflow-hidden rounded-lg p-4 shadow-lg transition-transform">
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteImage(index);
                    }}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Delete") {
                        e.stopPropagation();
                        deleteImage(index);
                      }
                    }}
                    role="button"
                    aria-label="Delete image"
                    className="absolute inset-x-0 bottom-0 flex translate-y-full cursor-pointer items-center justify-between bg-red-100 py-2 px-2 duration-300 hover:bg-red-300 group-hover:translate-y-0"
                  >
                    <p className="font-bold">
                      {selectedContent[localizationKeys.deleteImage]} :
                    </p>
                    <MdDeleteForever color="red" size={20} />
                  </div>
                  <img
                    src={url?.fileLink}
                    alt={`Uploaded ${index + 1}`}
                    className="w-full rounded-md bg-cover bg-center"
                  />
                </div>
              </div>
            ))}

            {(imageUrls?.length === 3 || imageUrls?.length > 3) && (
              <div
                className="mx-auto cursor-pointer rounded-lg"
                style={{
                  backgroundImage: `url(${imageUrls[0]?.fileLink})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
                onClick={() => {
                  history.push(routes.viewImages.viewAllImagesInSetting);
                }}
              >
                <div className="mt-2 mr-2 flex h-48 w-48 items-center justify-center rounded-lg bg-gray-200 bg-opacity-75 text-primary-900 transition-all duration-300 hover:bg-gray-300 hover:bg-opacity-75">
                  <h3>{selectedContent[localizationKeys.viewAllAlbum]}</h3>
                </div>
              </div>
            )}
          </div>
        ) : (
          <p className="text-gray-500">
            {selectedContent[localizationKeys.noImagesUploaded]}
          </p>
        )}
      </div>
    </section>
  );
}
