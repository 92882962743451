import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { authAxios } from "../../../config/axios-config";
import api from "../../../api";
import { IoMdClose } from "react-icons/io";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Loader } from "semantic-ui-react";

export default function ViewAllImagesInDetails() {
  const [imageUrls, setImageUrls] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const schoolId = localStorage.getItem("id");

  const fetchAllImages = async () => {
    try {
      setIsLoading(true);
      const response = await authAxios.get(
        api.schoolAlbum.get(schoolId, 0, 100)
      );
      setImageUrls(response?.data?.data?.albums);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleImageChange = (index) => {
    const image = imageUrls[index]?.fileLink;
    setCurrentImage(image);
    setShowImage(true);
  };

  const handleNext = () => {
    const nextIndex = (currentIndex + 1) % imageUrls.length;
    setCurrentIndex(nextIndex);
    setCurrentImage(imageUrls[nextIndex]?.fileLink);
  };

  const handlePrev = () => {
    const prevIndex = (currentIndex - 1 + imageUrls.length) % imageUrls.length;
    setCurrentIndex(prevIndex);
    setCurrentImage(imageUrls[prevIndex]?.fileLink);
  };

  useEffect(() => {
    fetchAllImages();
  }, [schoolId]);
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") setShowImage(false);
      if (e.key === "ArrowRight") handleNext();
      if (e.key === "ArrowLeft") handlePrev();
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex, imageUrls]);
  return (
    <section className="p-4">
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Loader active={true} size="small" className="mt-4" />
        </div>
      ) : (
        <>
          <div
            onClick={() => history.goBack()}
            className="my-4 w-fit cursor-pointer rounded-full border border-primary-900 px-3 py-3 text-primary-900 transition-all duration-300 hover:bg-primary-900 hover:text-white rtl:rotate-180"
          >
            <IoArrowBackOutline className="flex items-center justify-center " />
          </div>
          <div
            className={`flex flex-wrap items-center rounded-md p-4 ${
              imageUrls?.length > 0 ? "border border-gray-200" : ""
            }`}
          >
            {imageUrls?.length > 0 && (
              <div className="flex w-full flex-wrap items-center">
                <div
                  className={`fixed inset-0 z-[200] bg-black bg-opacity-75 ${
                    showImage ? "visible" : "hidden"
                  }`}
                >
                  <button
                    onClick={() => setShowImage(false)}
                    className="absolute right-6 top-4 rounded-md bg-red-400 p-3 text-white hover:bg-red-500"
                  >
                    <IoMdClose />
                  </button>
                  <button
                    onClick={handleNext}
                    className="absolute right-4 top-1/2 -translate-y-1/2 rounded-md bg-white p-2"
                  >
                    <FaAngleRight />
                  </button>
                  <button
                    onClick={handlePrev}
                    className="absolute left-4 top-1/2 -translate-y-1/2 rounded-md bg-white p-2"
                  >
                    <FaAngleLeft />
                  </button>
                  <div className="mx-auto flex items-center justify-center">
                    <img
                      className="h-[100vh] w-[90vw] rounded-lg border border-gray-300 object-contain shadow-lg"
                      src={currentImage}
                      alt="Expanded View"
                    />
                  </div>
                </div>
                {imageUrls?.map((url, index) => (
                  <>
                    <div
                      onClick={() => handleImageChange(index)}
                      key={index}
                      className="w-full cursor-pointer p-4 sm:w-1/3 md:w-1/4"
                    >
                      <div className="group relative my-4 mx-auto overflow-hidden rounded-lg p-4 shadow-lg transition-transform">
                        <img
                          src={url?.fileLink}
                          alt={`Uploaded ${index + 1}`}
                          className="w-full rounded-md bg-cover bg-center"
                        />
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
}
