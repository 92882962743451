const localizationKeys = {
  ejad: "ejad",
  login: "login",
  loginButton: "loginButton",
  registration: "registration",
  email: "email",
  password: "password",
  forgotPassword: "forgotPassword",
  dontHaveAnAccont: "dontHaveAnAccont",
  required: "required",
  emailError: "emailError",
  name_ar: "name_ar",
  name_en: "name_en",
  phone: "phone",
  confirmPassword: "confirmPassword",
  alreadyHaveAnAccount: "alreadyHaveAnAccount",
  signupEmailVerificationMessage: "signupEmailVerificationMessage",
  phoneMustBeNumber: "phoneMustBeNumber",
  phoneMustBeNineCharchters: "phoneMustBeNineCharchters",
  recoverPassword: "recoverPassword",
  recover: "recover",
  verifyEmailAddressMessage: "verifyEmailAddressMessage",
  verifyEmailAddress: "verifyEmailAddress",
  resetPasswordMessage: "resetPasswordMessage",
  changePassword: "changePassword",
  checkEmailResetLink: "checkEmailResetLink",
  welcomeMessage: "welcomeMessage",
  passwordError: "passwordError",
  confirmPasswordMssg: "confirmPasswordMssg",
  completeProfileTitle: "completeProfileTitle",
  thisDataIsRequired: "thisDataIsRequired",
  city: "city",
  educationalOffice: "educationalOffice",
  educationalLevels: "educationalLevels",
  schoolType: "schoolType",
  registrationNumber: " registrationNumber",
  address: "address",
  studentType: "studentType",
  uploadPhotos: "uploadPhotos",
  male: "male",
  female: "female",
  edit: "edit",
  brief: "brief",
  addLogo: "addLogo",
  addImage: "addImage",
  logout: "logout",
  cancelButton: "cancelButton",
  confirmButton: "confirmButton",
  logoutMessage: "logoutMessage",
  school: "school",
  vacancies: "vacancies",
  opened: "opened",
  closed: "closed",
  emailVerification: "emailVerification",
  emailVerificationText: "emailVerificationText",
  pleaseCompleteYourProfile: "pleaseCompleteYourProfile",
  pleaseCompleteYourProfileText: "pleaseCompleteYourProfileText",
  goToProfile: "goToProfile",
  save: "save",
  delete: "delete",
  updatedSuccessfully: "updatedSuccessfully",
  create: "create",
  gender: "gender",
  educationalOfficeName: "educationalOfficeName",
  addEducationalOffice: "addEducationalOffice",
  createdSuccessfully: "createdSuccessfully",
  cancel: "cancel",
  mainPage: "mainPage",
  YouHave: " YouHave",
  publicationsLeft: "publicationsLeft",
  addNewVacancy: "addNewVacancy",
  pastPublications: "pastPublications",
  thereAreNoPublications: "thereAreNoPublications",
  drafted: "drafted",
  active: "active",
  archived: "archive",
  filterBySubject: "filterBySubject",
  eduLevel: "eduLevel",
  track: "track",
  typeOfInstructors: "typeOfInstructors",
  salary: "salary",
  RS: "RS",
  determinedAtTheInterview: "determinedAtTheInterview",
  more: "more",
  close: "close",
  vacancyDetails: "vacancyDetails",
  vacancy: "vacancy",
  subject: "subject",
  educationalLevel: "educationalLevel",
  publishedDate: "publishedDate",
  numberOfApplied: "numberOfApplied",
  applicant: "applicant",
  jobDescription: "jobDescription",
  deleteVacancy: "deleteVacancy",
  yesDeleteVacancy: "yesDeleteVacancy",
  afterDeletionMessage: "afterDeletionMessage",
  no: "no",
  deleteVacancyNote: "deleteVacancyNote",
  closeVacancyTitle: "closeVacancyTitle",
  closeVacancyNote: "closeVacancyNote",
  vacancyIsClosedSuccessfully: "vacancyIsClosedSuccessfully",
  yesCloseVacancy: "yesCloseVacancy",
  addNewJobPosting: "addNewJobPosting",
  stepOne: "stepOne",
  stepTwo: "stepTwo",
  jobName: "jobName",
  description: "description",
  workingPlace: "workingPlace",
  workingTime: "workingTime",
  saveDraft: "saveDraft",
  publish: "publish",
  editJobPosting: "editJobPosting",
  descriptionPlaceHolder: "descriptionPlaceHolder",
  male2: "male2",
  female2: "female2",
  next: "next",
  noResultMessage: "noResultMessage",
  fileName: "fileName",
  employmentApplications: "employmentApplications",
  jobs: "jobs",
  drafted2: "drafted2",
  active2: "active2",
  archived2: "archived2",
  paying: "paying",
  settings: "settings",
  language: "language",
  arabic: "arabic",
  english: "english",
  noDraftedJobs: "noDraftedJobs",
  noClosedJobs: "noClosedJobs",
  applied: "applied",
  freePublicationsLeft: "freePublicationsLeft",
  applicantName: "applicantName",
  cv: "cv",
  status: "status",
  actions: "actions",
  all: "all",
  view: "view",
  isViewed: "isViewed",
  isNotViewed: "isNotViewed",

  download: "download",
  underReview: "underReview",
  approved: "approved",
  notApproved: "notApproved",
  approve: "approve",
  decline: "decline",
  approvingTitle: "approvingTitle",
  approvingBody: "approvingBody",
  rejectingTitle: "rejectingTitle",
  rejectingBody: "rejectingBody",
  approvedSuccessfully: "approvedSuccessfully",
  rejectedSuccessfully: "rejectedSuccessfully",
  freeTrial: "freeTrial",
  noEmploymentApplications: "noEmploymentApplications",
  profileIsCompletedSuccessfully: "profileIsCompletedSuccessfully",
  editLogo: "editLogo",
  editImage: "editImage",
  publishedSuccessfully: "publishedSuccessfully",
  publishVacancyTitle: "publishVacancyTitle",
  loading: "loading",
  failedMessage: "failedMessage",
  returnHome: "returnHome",
  errorCode: "errorCode",
  okUnderstand: "okUnderstand",
  MF001: "MF001",
  MF002: "MF002",
  MF003: "MF003",
  MF004: "MF004",
  MF005: "MF005",
  MF006: "MF006",
  MF007: "MF007",
  MF008: "MF008",
  MF009: "MF009",
  MF010: "MF010",
  MF020: "MF020",
  vacancyPost: "vacancyPost",
  dateAndTime: "date&Time",
  price: "price",
  noSubscribtionsFound: "noSubscribtionsFound",
  deletedAccount: " deletedAccount",
  noJobApplicationsInThisJob: " noJobApplicationsInThisJob",
  resetPassword: "resetPassword",
  submit: "submit",
  addNewEducationalOffice: "addNewEducationalOffice",
  agreeToAllTermsAndConditions: "agreeToAllTermsAndConditions",
  send: "send",
  forHelp: "forHelp",
  followUsOn: "followUsOn",
  callUs: "callUs",
  userGuide: "userGuide",
  footer: "footer",
  aboutUs1: "aboutUs1",
  aboutUs2: "aboutUs2",
  noInternet: "noInternet",
  services: "services",
  aboutUs: "aboutUs",
  contactUs: "contactUs",
  ourServices: "ourServices",
  ourServicesBody: "ourServicesBody",
  service1Title: " service1Title",
  service1Body: "service1Body",
  service2Title: " service2Title",
  service2Body: "service2Body",
  service3Title: " service3Title",
  service3Body: "service3Body",
  service4Title: " service4Title",
  service4Body: "service4Body",
  mockupTitle: "mockupTitle",
  mockupBody: "mockupBody",
  mockupBody2: "mockupBody2",
  getItFrom: "getItFrom",
  downloadFrom: "downloadFrom",
  aboutEjad: "aboutEjad",
  aboutEjadBody: "aboutEjadBody",
  contactUsBody: "contactUsBody",
  writeHere: "writeHere",
  fullName: "fullName",
  landingPageTitle: "landingPageTitle",
  landingPageBody: "landingPageBody",
  sendSuccessfully: "sendSuccessfull",
  emailIsNotVerified: "emailIsNotVerified",
  verifyEmailThroughThisLink: "verifyEmailThroughThisLink",
  linkIsSentSuccessfully: "linkIsSentSuccessfully",
  privacyPolicyTitle: "privacyPolicyTitle",
  privacyPolicyBody: "privacyPolicyBody",
  contestTitle: "contestTitle",
  contestBody: "contestBody",
  informationWeCollectTitle: "informationWeCollectTitle",
  informationWeCollectBody: "informationWeCollectBody",
  howWeUseYourInformationTitle: "howWeUseYourInformationTitle",
  howWeUseYourInformationBody: "howWeUseYourInformationBody",
  logFilesTitle: "logFilesTitle",
  logFilesBody: "logFilesBody",
  cookiesAndWebBeaconsTitle: "cookiesAndWebBeaconsTitle",
  cookiesAndWebBeaconsBody: "cookiesAndWebBeaconsBody",
  advertisingPartnersPrivacyPoliciesTitle:
    "advertisingPartnersPrivacyPoliciesTitle",
  advertisingPartnersPrivacyPoliciesBody:
    "advertisingPartnersPrivacyPoliciesBody",
  thirdPartyPrivacyPoliciesTitle: "thirdPartyPrivacyPoliciesTitle",
  thirdPartyPrivacyPoliciesBody: "thirdPartyPrivacyPoliciesBody",
  CCPAPrivacyRightsTitle: "CCPAPrivacyRightsTitle",
  CCPAPrivacyRightsBody: "CCPAPrivacyRightsBody",
  GDPRDataProtectionRightsTitle: "GDPRDataProtectionRightsTitle",
  GDPRDataProtectionRightsBody: "GDPRDataProtectionRightsBody",
  ChildrensInformationTitle: "ChildrensInformationTitle",
  ChildrensInformationBody: "ChildrensInformationBody",
  phoneNumberPlaceHolder: "phoneNumberPlaceHolder",
  hideSchoolIdentity: "hideSchoolIdentity",
  agreeToAll: "agreeToAll",
  termsAndConditions: "termsAndConditions",
  notificationsNotFound: "notificationsNotFound",
  now: "now",
  from: "from",
  viewAll: "viewAll",
  ago: "ago",
  minute: "minute",
  hour: "hour",
  notifications: "notifications",
  notification: "notification",
  body: "body",
  hideIdentityTitle: "hideIdentityTitle",
  hideIdentityBody: "hideIdentityBody",
  hiddenIdentity: "hiddenIdentity",
  hiddenIdentityInfo: "hiddenIdentityInfo",
  instructor: "instructor",
  instructors: "instructors",
  instructorsPlural: "instructorsPlural",
  englishLanguageLevel: "englishLanguageLevel",
  studies: "studies",
  educationalQualification: "educationalQualification",
  specialization: "specialization",
  graduationYear: "graduationYear",
  trainingCourses: "trainingCourses",
  courseName: "courseName",
  theOrganization: "theOrganization",
  courseDuration: "courseDuration",
  certificate: "certificate",
  pastExperience: "pastExperience",
  schoolName: "schoolName",
  yearsOfExperience: "yearsOfExperience",
  instructorName: "instructorName",
  nationality: "nationality",
  remainLimitPopup: "remainLimitPopup",
  payToView: "payToView",
  gotEjadCertificate: "gotEjadCertificate",
  notPermittedWithoutBuy: "notPermittedWithoutBuy",
  wantToBuy: "wantToBuy",
  searchBySubject: "searchBySubject",
  reset: "reset",
  filter: "filter",
  pastTrainingCourses: "pastTrainingCourses",
  mobileNumber: "mobileNumber",
  specializationDegree: "specializationDegree",
  courseOrganization: "courseOrganization",
  jobTitle: "jobTitle",
  experienceYears: "experienceYears",
  BreakingLimits: "BreakingLimits",
  DownloadMaterialByEnglish: "DownloadMaterialByEnglish",
  DownloadMaterialByArabic: "DownloadMaterialByArabic",
  allSchoolsProfiles: "allSchoolsProfiles",

  programInfo: "programInfo",
  add_edit_programInfo: "add-edit-schoolInformation",
  teachersName: "teachersName",
  teachersNameAr: "teachersNameAr",
  teachersNameEn: "teachersNameEn",
  teachersPhoneNumber: "teachersPhoneNumber",
  numberOfStudents: "numberOfStudents",
  numberOfBoys: "numberOfBoys",
  numberOfGirls: "numberOfGirls",
  educationalStage: "educationalStage",
  numberOfServings: "NumberOfServings",
  save: "save",
  uploadImage: "uploadImage",
  deleteImage: "deleteImage",
  deleteImageTitle: "deleteImageTitle",
  deleteImageText: "deleteImageText",
  deleteImageConfirm: "deleteImageConfirm",
  deleteImageCancel: "deleteImageCancel",
  limitUploadImageTitle: "limitUploadImageTitle",
  limitUploadImageText: "limitUploadImageText",
  limitUploadImageConfirm: "limitUploadImageConfirm",
  viewAllAlbum: "viewAllAlbum",
  noImagesUploaded: "noImagesUploaded",
  uploadImageSuccessTitle: "uploadImageSuccessTitle",
  studentGender: "studentGender",
  educationProgramUpdated: "educationProgramUpdated",
  deleteImageTitle: "deleteImageTitle",
  deleteImageText: "deleteImageText",
  deleteImageConfirm: "deleteImageConfirm",
  deletedImage: "deletedImage",
  deletedImageText: "deletedImageText",
};

export default localizationKeys;
